<ng-container *ngIf="report$ | ngrxPush as report">
    <ng-container *ngrxLet="isStatusUpdateProcessing$ as isStatusUpdateProcessing">
        <div class="header-container">
            <h3>
                <dtm-ui-icon name="alarm-warning"></dtm-ui-icon>
                {{ "uavIdSharedLibReport.reportPreviewDetails.header" | transloco }}
                <ng-content select="[headerAdditionalContent]"></ng-content>
            </h3>

            <span class="badges-list">
                <span
                    *ngIf="report.isEmergencyReport"
                    class="chip light"
                    [matTooltip]="'uavIdSharedLibReport.reportPreviewDetails.emergencyReportTooltip' | transloco"
                >
                    {{ "uavIdSharedLibReport.reportPreviewDetails.emergencyReportLabel" | transloco }}
                </span>

                <ng-container *ngIf="canChangeStatus$ | ngrxPush; else statusBadgeTemplate">
                    <uav-id-shared-lib-status-badge-menu
                        *ngIf="!isStatusUpdateProcessing; else loadingSpinner"
                        [configuration]="REPORT_STATUS_BADGE_CONFIGURATION"
                        [availableStatuses]="availableUpdateStatuses$ | ngrxPush"
                        [status]="report.status"
                        (statusChange)="statusUpdate.emit($event)"
                    ></uav-id-shared-lib-status-badge-menu>

                    <ng-template #loadingSpinner>
                        <dtm-ui-loader [shouldSetParentPositionRelative]="true" [isShown]="true" [diameter]="30"></dtm-ui-loader>
                    </ng-template>
                </ng-container>

                <ng-template #statusBadgeTemplate>
                    <uav-id-shared-lib-status-badge [configuration]="REPORT_STATUS_BADGE_CONFIGURATION" [status]="report.status">
                    </uav-id-shared-lib-status-badge>
                </ng-template>
            </span>
        </div>

        <div class="split-content-container">
            <div>
                <div class="label">{{ "uavIdSharedLibReport.reportPreviewDetails.numberLabel" | transloco }}</div>
                <div class="value">{{ report.number }}</div>
            </div>

            <div>
                <div class="label">{{ "uavIdSharedLibReport.reportPreviewDetails.timeLabel" | transloco }}</div>
                <div class="value">
                    {{ (report.createdAt | localizeDate : { dateStyle: "short", timeStyle: "short" }) ?? "-" }}
                </div>
            </div>
        </div>
        <div>
            <div class="label">{{ "uavIdSharedLibReport.reportPreviewDetails.addressLabel" | transloco }}</div>
            <div class="value">{{ report.address }}</div>
        </div>
        <div>
            <div class="label">
                {{ "uavIdSharedLibReport.reportPreviewDetails.flightsNumberLabel" | transloco }}
                <dtm-ui-popover
                    [popoverText]="
                        'uavIdSharedLibReport.reportPreviewDetails.flightsNumberHelpMessage'
                            | transloco : { radius: report.flightsSearchRadius | invoke : getRadiusInKm }
                    "
                ></dtm-ui-popover>
            </div>
            <div class="value">{{ report.flightsNumber }}</div>
        </div>

        <hr />

        <div>
            <div class="label">
                {{ "uavIdSharedLibReport.reportPreviewDetails.uavFlightAltitudeLabel" | transloco }}
            </div>
            <div class="value" [ngSwitch]="report.uav.height">
                <dtm-ui-icon *ngSwitchCase="ReportUavHeightEstimation.AroundFive" name="base-station"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchCase="ReportUavHeightEstimation.AroundTen" name="home-2"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchCase="ReportUavHeightEstimation.AroundTwentyFive" name="tree"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchCase="ReportUavHeightEstimation.AboveFifty" name="building"></dtm-ui-icon>
                <dtm-ui-icon *ngSwitchCase="ReportUavHeightEstimation.CantDetermine" name="question"></dtm-ui-icon>
                {{ "uavIdSharedLibReport.reportPreviewDetails.uavFlightAltitudeValue" | transloco : { value: report.uav.height } }}
                <ng-container *ngIf="report.uav.height !== ReportUavHeightEstimation.CantDetermine">&nbsp;-&nbsp;</ng-container>
                {{
                    "uavIdSharedLibReport.reportPreviewDetails.uavFlightAltitudeValueDescription" | transloco : { value: report.uav.height }
                }}
            </div>
        </div>
        <div>
            <div class="label">
                {{ "uavIdSharedLibReport.reportPreviewDetails.pilotVisibleLabel" | transloco }}
            </div>
            <div class="value">
                {{ "uavIdSharedLibReport.reportPreviewDetails.pilotVisibleValue" | transloco : { value: report.pilot.isVisible } }}
            </div>
        </div>
        <div *ngIf="report.pilot.isVisible">
            <div class="label">
                {{ "uavIdSharedLibReport.reportPreviewDetails.pilotDescriptionLabel" | transloco }}
            </div>
            <div class="value">{{ report.pilot.description }}</div>
        </div>
        <div>
            <div class="label">
                {{ "uavIdSharedLibReport.reportPreviewDetails.reportReasonLabel" | transloco }}
            </div>
            <div class="value">
                <ul class="reasons-list">
                    <li *ngFor="let reason of report.reasons">
                        <ng-container [ngSwitch]="reason">
                            <dtm-ui-icon *ngSwitchCase="ReportReason.NotVisibleOnMap" name="eye-off"></dtm-ui-icon>
                            <dtm-ui-icon *ngSwitchCase="ReportReason.DangerousManeuvers" name="error-warning"></dtm-ui-icon>
                            <dtm-ui-icon *ngSwitchCase="ReportReason.FlightOverPrivateArea" name="forbid"></dtm-ui-icon>
                            <dtm-ui-icon *ngSwitchCase="ReportReason.IllegalRecording" name="camera"></dtm-ui-icon>
                            <dtm-ui-icon *ngSwitchCase="ReportReason.CrashedUav" name="flight-land"></dtm-ui-icon>
                            <dtm-ui-icon *ngSwitchCase="ReportReason.HealthOrPropertyDamage" name="first-aid-kit-fill"></dtm-ui-icon>
                            <dtm-ui-icon *ngSwitchCase="ReportReason.Other" name="add-circle"></dtm-ui-icon>
                        </ng-container>
                        <span>
                            {{ "uavIdSharedLibReport.reportPreviewDetails.reportReasonValue" | transloco : { value: reason } }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <div class="label">
                {{ "uavIdSharedLibReport.reportPreviewDetails.additionalInformationLabel" | transloco }}
            </div>
            <div class="value">
                {{ report.additionalInfo.length ? report.additionalInfo : "-" }}
            </div>
        </div>
    </ng-container>
</ng-container>
